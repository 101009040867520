<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con mt-30">
                <div class="con_table">
                    <div class="search2 mb-30">
                        <div class="float-left dp-block mb-10">
                            <button type="button" class="default-btn">오늘</button>
                            <button type="button" class="default-btn ml-10">금주</button>
                            <button type="button" class="default-btn ml-10">금월</button>
                        </div>

                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <label>기간검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <span class="font-14">월</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">화</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">수</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">목</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">금</span> <input type="checkbox" class="mr-10">
                            <span class="font-14">토</span> <input type="checkbox" class="mr-20"><br>
                        </label>
                    </div>

                    <div class="default_tab">
                        <div class="tabs" @click="tabType = 'distinct'" :class="{'active': tabType === 'distinct'}">
                            구분별
                        </div>
                        <div class="tabs" @click="tabType = 'type'" :class="{'active': tabType === 'type'}">
                            유형별
                        </div>
                        <div class="tabs" @click="tabType = 'topic'" :class="{'active': tabType === 'topic'}">
                            주제별
                        </div>
                        <div class="tabs" @click="tabType = 'gender'" :class="{'active': tabType === 'gender'}">
                            남녀성비
                        </div>
                        <div class="tabs" @click="tabType = 'age'" :class="{'active': tabType === 'age'}">
                            연령별
                        </div>
                    </div>
                    <div class="bound_table">
                        <button type="button" class="down-btn float-right mb-20 mr-5per">엑셀파일 다운로드</button>
                        <table v-if="tabType === 'consult'" class="default_table w-90per ml-5per">
                            <tbody>
                                <tr>
                                    <th>상담사명</th>
                                    <th>내담자수</th>
                                    <th>예약수</th>
                                    <th>상담진행수</th>
                                    <th>취소율</th>
                                    <th>평균회기수</th>
                                    <th>회전율</th>
                                    <th>매출액</th>
                                </tr>
                                <tr v-for="(item, index) of counselors" :key="index">
                                    <td>{{item.customer}}</td>
                                    <td>{{item.visitor}}</td>
                                    <td>{{item.reserveCount}}</td>
                                    <td>{{item.consultCount}}</td>
                                    <td>{{item.cancelPercent}}%</td>
                                    <td>{{item.averageCount}}</td>
                                    <td>{{item.rotatePercent}}%</td>
                                    <td>{{item.salePrice}}원</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'distinct'" class="default_table w-90per ml-5per">
                            <tbody>
                                <tr>
                                    <th>상품구분</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of distincts" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.visitor}}</td>
                                    <td>{{item.reserveCount}}</td>
                                    <td>{{item.consultCount}}</td>
                                    <td>{{item.testCount}}</td>
                                    <td>{{item.rotatePercent}}%</td>
                                    <td>{{item.averageCount}}</td>
                                    <td>{{item.cancelPercent}}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'type'" class="default_table w-90per ml-5per">
                            <tbody>
                                <tr>
                                    <th>상품유형</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of types" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.visitor}}</td>
                                    <td>{{item.reserveCount}}</td>
                                    <td>{{item.consultCount}}</td>
                                    <td>{{item.testCount}}</td>
                                    <td>{{item.rotatePercent}}%</td>
                                    <td>{{item.averageCount}}</td>
                                    <td>{{item.cancelPercent}}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'topic'" class="default_table w-90per ml-5per">
                            <tbody>
                                <tr>
                                    <th>상품주제</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of topics" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.visitor}}</td>
                                    <td>{{item.reserveCount}}</td>
                                    <td>{{item.consultCount}}</td>
                                    <td>{{item.testCount}}</td>
                                    <td>{{item.rotatePercent}}%</td>
                                    <td>{{item.averageCount}}</td>
                                    <td>{{item.cancelPercent}}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'gender'" class="default_table w-90per ml-5per">
                            <tbody>
                                <tr>
                                    <th>성별</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of genders" :key="index">
                                    <td style="background: #FCFCFC;">{{item.gender}}</td>
                                    <td>{{item.visitor}}</td>
                                    <td>{{item.reserveCount}}</td>
                                    <td>{{item.consultCount}}</td>
                                    <td>{{item.testCount}}</td>
                                    <td>{{item.rotatePercent}}%</td>
                                    <td>{{item.averageCount}}</td>
                                    <td>{{item.cancelPercent}}%</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="tabType === 'age'" class="default_table w-90per ml-5per">
                            <tbody>
                                <tr>
                                    <th>연령</th>
                                    <th>내담자수</th>
                                    <th>예약건수</th>
                                    <th>상담건수</th>
                                    <th>검사건수</th>
                                    <th>회전율</th>
                                    <th>평균회기</th>
                                    <th>취소율</th>
                                </tr>
                                <tr v-for="(item, index) of ages" :key="index">
                                    <td style="background: #FCFCFC;">{{item.type}}</td>
                                    <td>{{item.visitor}}</td>
                                    <td>{{item.reserveCount}}</td>
                                    <td>{{item.consultCount}}</td>
                                    <td>{{item.testCount}}</td>
                                    <td>{{item.rotatePercent}}%</td>
                                    <td>{{item.averageCount}}</td>
                                    <td>{{item.cancelPercent}}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
export default {
    components: {

    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜

        tabType: 'distinct', // 탭 타입
        distincts: [{
                type: '일반',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: 'EAP',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '도박',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '범피',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '기타',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            }
        ],
        types: [{
                type: '아동',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '청소년',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '성인',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '부부',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '커플',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '가족',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '기타',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            }
        ],
        topics: [{
                type: '심리/정서',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '가정',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '직장',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '기타',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
        ],
        genders: [{
                gender: '남자',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                gender: '여자',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
        ],
        ages: [{
                type: '10세이하',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '20대',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '30대',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '40대',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '50대',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
            {
                type: '60대 이상',
                visitor: 17,
                reserveCount: 15,
                consultCount: 12,
                testCount: 16,
                rotatePercent: 91,
                averageCount: 2,
                cancelPercent: 5
            },
        ],
    }),

    mounted() {

    },
    methods: {

    }
}
</script>
